import { createContext, useContext, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { contracts, mainnet } from './config';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { InjectedConnector } from "@web3-react/injected-connector";

const injected = new InjectedConnector({
    supportedChainIds: [56, 97]
});

const walletconnect = new WalletConnectConnector({
    rpc: {
        97: mainnet.chain_info.rpcUrls[0]
    },
    chainId: 97,
    supportedChainIds: [97, 56]
})

const AuthContext = createContext({})

export const useAuth = () => useContext(AuthContext)

export const AuthContextProvider = ({children}) => {
    const web3 = useWeb3React()
    const [web3type, setWeb3type] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tokenct, setTokenct] = useState(null)
    const [gamect, setGamect] = useState(null)

    useEffect(() => {
        setWeb3type(localStorage.getItem("web3type"))
    }, [])

    useEffect(() => {
        if (web3type === "mtm" && web3 && !web3.active) {
            web3.activate(injected)
        }
        else if (web3type === "wc" && web3 && !web3.active) {
            web3.activate(walletconnect)
        }
    }, [web3type])

    useEffect(() => {
        if (web3 && web3.active && web3.chainId === mainnet.chain_num) {
            setTokenct(new web3.library.eth.Contract(contracts.token.abi, contracts.token.contract))
            setGamect(new web3.library.eth.Contract(contracts.game.abi, contracts.game.contract))
        }
    }, [web3])

    const changeNetwork = async () => {
        try {
            await web3.library._provider.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: mainnet.chain_info.chainId
                    }
                ]
            });
        } catch (switchError) {
            console.log(switchError)
            try {
                await web3.library._provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        mainnet.chain_info
                    ]
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const web3main = new Web3(new Web3.providers.HttpProvider( mainnet.chain_info.rpcUrls[0]));

    return (
        <AuthContext.Provider value={{
            web3, web3main, setWeb3type,
            loading, setLoading, changeNetwork,
            tokenct, gamect,
        }}>
            {children}
        </AuthContext.Provider>
    )
}