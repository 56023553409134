import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore";

const firebaseConfig = { // snapn
    apiKey: "AIzaSyAprFwAt9SxyjwFQuOd2qeaJcObkezmQKo",
    authDomain: "snapn-ed5ad.firebaseapp.com",
    databaseURL: "https://snapn-ed5ad-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "snapn-ed5ad",
    storageBucket: "snapn-ed5ad.appspot.com",
    messagingSenderId: "80209074258",
    appId: "1:80209074258:web:0a2e5e834b177948b9278b",
    measurementId: "G-75BM3MEQE8"
}

// { // 3s
//     apiKey: "AIzaSyCkIqz7zmQ2ipHhp0PbazGz1IoH8Pyuq20",
//     authDomain: "stool-test.firebaseapp.com",
//     databaseURL: "https://stool-test-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "stool-test",
//     storageBucket: "stool-test.appspot.com",
//     messagingSenderId: "211645279599",
//     appId: "1:211645279599:web:c2c5aafe2a424b4acb0c9a"
// }

// { test
//     apiKey: "AIzaSyAa5OvL0WpvESvgRXzmfGiCw5NVA-m8kps",
//     authDomain: "test-d9420.firebaseapp.com",
//     projectId: "test-d9420",
//     storageBucket: "test-d9420.appspot.com",
//     messagingSenderId: "946186319251",
//     appId: "1:946186319251:web:d35b27101680f3bcd9a466"
// }

const app = initializeApp(firebaseConfig)
export const mydb = getFirestore(app)