import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Web3 from "web3";
import { AuthContextProvider } from "./context/authContext";
import Index from "./pages";

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <AuthContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*">
              <Route path="*" element={<Index />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthContextProvider>
    </Web3ReactProvider>
  );
}

export default App;
